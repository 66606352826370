<template>
    <div>
    <div class="title">  <router-link to="/AdminDash/Invoices"  class="button">Return</router-link> رقم الفاتورة  : #{{ this.$route.params.id }}</div>
        <div>
             الفاتورة  {{facture.amount}} MAD 
        </div>


         <div class="columns p-5">
      <div class="column">
        معلومات البنك : <b>{{ facture.bank_info }}</b
        ><br />

         created  : <b>{{ facture.created }}</b
        ><br />
      </div>

           <div class="column">
    balance_after : <b>{{ facture.balance_after }}</b
        ><br />

         balance_before  : <b>{{ facture.balance_before }}</b
        ><br />
      </div>

                <div class="column">
    is_paid : <b>{{ facture.is_paid }}</b
        ><br />

         transaction_id  : <b>{{ facture.transaction_id }}</b
        ><br />
      </div>

         </div>
    </div>

    
</template>

<script>
import axios from 'axios'
export default {
    name: 'viewFacture',
      data() {
    return {
            facture: {},
            loading: true,
            };
        },

    mounted(){
        this.getFacture()

    },
    methods:{
        getFacture() {
      const track = this.$route.params.id;
      axios
        .get(`api/v1/getAdminFacture/${track}`)
        .then((response) => {
          this.facture = response.data;
          this.loading = false

        })
        .catch((error) => {
          console.log(error);
        });
    },
    }
}
</script>