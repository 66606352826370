<template>
  <div dir="rtl" id="wrapper">
    <navbar />




    <div  class="section is-light">
      <router-view class="content is-light" />
    </div>


  </div>
</template>



<script>
import axios from 'axios'
import 'vue-select/dist/vue-select.css';

import Navbar from './components/layout/Navbar.vue'
export default {
  components: { Navbar },
  data() {
    return {
      showMobileMenu: false,
      cart: {
        items: []
      },
      categories : []
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')
    const token = this.$store.state.token
    if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
        axios.defaults.headers.common['Authorization'] = ""
    }

  },
  mounted() {
    this.cart = this.$store.state.cart

    this.getCategories()
    document.title = 'Bi3 -  Site Official'
  },
  computed: {
      cartTotalLength() {
          let totalLength = 0
          for (let i = 0; i < this.cart.items.length; i++) {
              totalLength += this.cart.items[i].quantity
          }
          return totalLength
      }
  },
  methods:{
    getCategories(){
      axios
        .get('/api/v1/categories-list/')
        .then(response =>{
          this.categories = response.data
        })
        .catch(error =>{
          console.log('coudnt load categories');
        })
    }
  }
}
</script>


<style lang="scss">
@import "../node_modules/bulma";
</style>
