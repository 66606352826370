<template>
  <div dir="rtl" class="page-checkout">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">الفاتورة</h1>
      </div>

      <div class="column is-5">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>المنتج</th>
              <th>الثمن</th>
              <th>الكمية</th>
              <th>المجموع</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in cart.items" v-bind:key="item.product.id">
              <td>{{ item.product.name }}</td>
              <td>{{ item.product.price }} درهم</td>
              <td>{{ item.quantity }}</td>
              <td>{{ getItemTotal(item) }} درهم</td>
            </tr>
          </tbody>
          <br />

          <tbody class="">
            <tr class="card">
              <td colspan="2">سعر المنتجات</td>
              <td>{{ cartTotalLength }}</td>
              <td>{{ cartTotalPrice }} درهم</td>
            </tr>
          </tbody>
          <br />
          <tbody v-if="selectedCityCost" class="">
            <tr class="card">
              <td colspan="2">سعر الشحن</td>
              <td>{{selectedCityCost.ville}}</td>
              <td>{{selectedCityCost.prix_delivred}} درهم</td>
            </tr>
          </tbody>


       <tbody class="">
            <tr class="card">
              <td colspan="2">عمولة Bi3 {{bi3}}</td>
              <!-- <td v-if="amount <= 200">15%</td> -->
              <td v-if="amount">10%</td>

              <td >{{ (amount * 0.1).toFixed(2) }} درهم</td>
              <!-- <td v-if="amount <= 200">{{ (amount * 0.15).toFixed(2) }} درهم</td> -->
            </tr>
          </tbody> 



          <tbody v-if="amount" class="is-primary">
            <tr class="card">
              <td colspan="2">التغليف و التوضيب</td>
              <td></td>
              <td>3 درهم</td>
            </tr>
          </tbody>

          <tbody v-if="amount" class="is-primary">
            <tr class="card">
              <td colspan="2">سعر التحصيل من العميل</td>
              <td></td>
              <td>{{ amount }} درهم</td>
            </tr>
          </tbody>
                    <tbody v-if="amount" class="is-primary">
            <tr class="card">
              <td colspan="2">تأكيد و تتبع الطلبية</td>
              <td></td>
              <td>10 درهم</td>
            </tr>
          </tbody>

          <br />

          <tfoot v-if="amount" class="">
            <tr style="background-color:#eee; " class="card is-light">
              <td class='' colspan="2">صافي الربح {{bi3}}</td>
              <td></td>
              <td><b class="is-white">{{ ((amount - cartTotalPrice - ((amount * (amount > 200 ? 0.1 : 0.15))).toFixed(2) - selectedCityCost.prix_delivred).toFixed(2) - 10 -3 ).toFixed(2)}} درهم</b></td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div class="column is-7 box">
        <h2 class="subtitle">معلومات الشحن:</h2>

        <p class="has-text-grey">* حقول إجبارية </p>

        <div class="columns is-multiline">
          <div class="column is-6">
            <div class="field">
              <label>اسم العميل*</label>
              <div class="control">
                <input type="text" placeholder="Ahmed.." class="input" v-model="name" />
              </div>
            </div>

            <div class="field">
              <label>رقم الهاتف *</label>
              <div class="control">
                <input type="text" placeholder="0612345678" class="input" v-model="phone" required />
              </div>
            </div>
                        <div class="field">
              <label>العنوان</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  placeholder="Boulevard .."
                  v-model="address"
                  required
                />
              </div>
            </div>

            <div class="field">
              <label>سعر التحصيل من العميل*</label>
              <div class="control">
                <input
                  type="text"
                  placeholder="199"
                  v-bind:min="cartTotalPrice"
                  class="input"
                  v-model="amount"
                  required
                />
              </div>
            </div>
          </div>

          <div class="column is-6">


<article class="panel is-info" :class="{'is-primary':selectedCityCost}">
  <p class="panel-heading">
    المدينة : {{selectedCityCost.ville}}
  </p>

  <div class="panel-block">
    <p class="control has-icons-left">
      <input class="input is-info" type="text" v-model="Citysearch"  placeholder="إضغط للبحث ">
      <span class="icon is-left">
        <i class="fas fa-search" aria-hidden="true"></i>
      </span>
    </p>
  </div>
  <div class="scroll" style="max-height:200px;">
  <a  @click="Choosecity(city.id); onChange()"  v-for="city in searchCity" :value="city.id"  :key="city.id" class="panel-block">

    {{city.ville}}
  </a>
  </div>
</article>


          </div>
        </div>

        <div class="notification is-danger mt-4" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>

        <hr />

        <div id="card-element" class="mb-5"></div>
        <template v-if='amount.length && name.length && amount.length  && phone.length  '>
                  <template v-if="cartTotalLength  ">
                    <button
                    
                      class="button is-dark is-dark is-large"
                      :class="{'is-loading' : isLoading } "
                      @click="submitToBackend"
                    >
                      إكمال الطلب
                    </button>
                  </template>
        </template>
    
          
            <button
            v-if='!(amount.length && name.length && amount.length  && phone.length)  '
            
              class="button is-static is-large"
              
              
            >
              إكمال الطلب
            </button>
     
     



      </div>
    </div>
  </div>



</template>

<script>
import axios from "axios";
export default {
  name: "CheckoutCOD",
  isLoading: false,
  data() {
    return {
      isLoading: false,
      cart: {
        items: [],
      },
      card: {},
      name: "",
      ville: "",
      selectedCity:'',
      selectedCityCost:'',
      address: "",
      phone: "",
      name: "",
      amount: "",
      bi3:'',
      Citysearch:'',

      errors: [],
      cities: [

      ],
    };
  },
  mounted() {
    document.title = "Complete Checkout | Bi3.ma";
    this.cart = this.$store.state.cart;
    this.getCities()
  },
  methods: {
    getItemTotal(item) {
      return item.quantity * item.product.price;
    },

    Choosecity(city){
      this.selectedCity = city

    },

    getCities(){
        axios.get('/api/v1/cities')
        .then(response =>{
            this.cities = response.data

        })
        .catch(error =>{
            console.log('error' , error);
        })

    },

    async submitToBackend() {
      this.isLoading = true;

      const items = [];
      for (let i = 0; i < this.cart.items.length; i++) {
        const item = this.cart.items[i];
        const obj = {
          product: item.product.id,
          quantity: item.quantity,
          price: item.product.price * item.quantity,
        };
        items.push(obj);
      }

      const data = {
        name: this.name,
        ville: this.selectedCity,
        address: this.address,
        phone: this.phone,
        amount: this.amount,

        items: items,
      };

      await axios

        .post("/api/v1/checkout/cod/", data)
        .then((response) => {
          this.$store.commit("clearCart");
          this.$router.push("/UserDash/Overview");
        })
        .catch((error) => {
                          toast({
          message: `${error} `,
          type : 'is-danger',
          dismissible: true,
          duration: 4000,
          position:'bottom-right',
        })
          this.errors.push(`${error}`);
          console.log(error);
        });

      this.isLoading = false;
    },
        onChange(){
      const city = this.selectedCity  ;
   

        axios

        .get(`/api/v1/city/${city}`)
        .then((response) => {
          this.selectedCityCost = response.data

        })
        .catch((error) => {
          this.errors.push(`${error}`);
          console.log(error);
        });


    },

  },
  computed: {
    cartTotalPrice() {
      return this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.product.price * curVal.quantity);
      }, 0);
    },
    cartTotalLength() {
      return this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.quantity);
      }, 0);
    },

 
    searchCity: function () {
      return this.cities.filter((city) => {
        return city.ville.toLowerCase().match(this.Citysearch.toLowerCase());
      });
    },






  },
};
</script>


<style scoped>
.scroll {
  
  width: 100%;
  height: 150px;
  overflow: auto;

}
</style>