<template>
  <div class="columns">
    <!-- <aside   class="column is-2 aside hero "> -->
      <!-- <div> -->
        <!-- <div class="compose has-text-centered">
          <router-link v-if="active"  to="/cart"  style="background-color:#eee;" class="button  is-block  is-bold">
            <span class="compose">إنشاء طلب</span>
          </router-link>
        </div> -->

        <!-- <div class="menu-list mt-3 "> -->
          <!-- <router-link to="/AdminDash/Overview" class="menu-list active"
            ><span class="icon"><i class="fa fa-inbox"></i></span
            ><span class="name ml-1">الطلبات</span></router-link
          > -->

        <!-- <router-link to="/AdminDash/Invoices" class="menu-list active"
            ><span class="icon"><i class="fas fa-file-alt"></i></span
            ><span class="name ml-1">رسائل </span></router-link
          > -->
          <!-- <router-link to="/AdminDash/Favs" class="menu-list"
            ><span class="icon"><i class="fa fa-star"></i></span
            ><span class="name ml-1">المنتجات المفضلة </span></router-link
          > -->


          <!-- <router-link to="/AdminDash/account" class="menu-list"
            ><span class="icon"><i class="fas fa-user"></i></span
            ><span class="name ml-1">الحساب </span></router-link
          >
          <router-link to="/AdminDash/products" class="menu-list"
            ><span class="icon"><i class="fas fa-box"></i></span
            ><span class="name ml-1">تصفح المنتجات </span></router-link
          > -->

          <!-- <router-link to="/AdminDash/Adminusers" class="menu-list"
            ><span class="icon"><i class="fas fa-user"></i></span
            ><span class="name ml-1">العملاء  </span></router-link
          > -->


          
        <!-- </div> -->
      <!-- </div> -->
    <!-- </aside> -->




  <router-view  class="column ">

  </router-view>



  </div>

</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "AdminDash",
  data(){
    return {
      active: true,

    }
  },

  mounted(){
    
  },

  methods: {

  
},


};
</script>
