<template>
  <div>
    <div class="title">Admin Dashboard Orders</div>
    <div class="">
      <!-- Main container -->
      <nav class="level box">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <p class="subtitle is-5"> Orders</p>
          </div>
          <div class=" level-item ">
            <div class="field has-addons">
              <p class="control">
                <input class="input is-medium" type="text" v-model="search" placeholder="search an order" />
              </p>

            </div>
           
          </div>
        </div>

        {{ visibility }}

        <!-- Right side -->
        <div class="level-right">
          <button class="button level-item is-rounded " @click="visibility = 'all'">
            All
          </button>
          <button class="button level-item is-rounded  " @click="visibility = 'Pending'">
            Pending
          </button>
          <button class="button level-item is-rounded " @click="visibility = 'Returned'">
            Returned
          </button>
          <button class="button level-item is-rounded " @click="visibility = 'Refused'">
            Refused
          </button>
          <button class="button level-item is-rounded " @click="visibility = 'Delivred'">
            Delivred
          </button>
        </div>
      </nav>
    </div>

    <div v-if="orders.length" class="is-fullwidth box">
      <AdminOrderSummary
        class="mb-4"
        v-for="order in searchOrders"
        :key="order.id"
        v-bind:order="order"
      />
    </div>
    <p v-else>You have no orders</p>
  </div>
</template>


<script>
import axios from "axios";
import { toast } from "bulma-toast";
import AdminOrderSummary from "@/views/AdminOrderSummary.vue/";

var filters = {
  all: function (orders) {
    return orders;
  },
  Pending: function (orders) {
    return orders.filter(function (order) {
      return order.status === "Pending";
    });
  },
  Refused: function (orders) {
    return orders.filter(function (order) {
      return order.status === "Refused";
    });
  },

  Returned: function (orders) {
    return orders.filter(function (order) {
      return order.status === "Returned";
    });
  },

  Delivred: function (orders) {
    return orders.filter(function (order) {
      return order.status === "Delivred";
    });
  },
};

export default {
  name: "AdminOrders",

  data() {
    return {
      visibility: "all",
      search: '',
      orders: [],
    };
  },
  components: {
    AdminOrderSummary,
  },

  mounted() {
    document.title = "Orders | Pro";

    this.getOrders();
  },
  methods: {
    getOrders() {
      axios
        .get("/api/v1/admin/orders/")
        .then((response) => {
          this.orders = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          toast({
            message: `${error}`,
            type: "is-danger",
            dismissible: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
    },
  },

  computed:{
      filteredOrders(){
          return filters[this.visibility] (this.orders)
      },
      searchOrders: function(){
          return this.filteredOrders.filter((order)=>{
              return order.name.match(this.search);

          })

      }
  }
};
</script>