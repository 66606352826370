<template>
  <div v-if="loading">
    <div class="columns p-5">
      <div class="column">
        <progress class="progress is-small is-light" max="30">15%</progress>
        <progress class="progress is-small is-light" max="30">25%</progress>
        <progress class="progress is-small is-light" max="30">35%</progress>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="title">
      <router-link to="/AdminDash/Overview" class="button">رجوع </router-link>
      الطلبية رقم : #{{ this.$route.params.id }}
    </div>
    <div></div>
    <div class="substitle ">
      الحالة :
      <span class="tag " :class="order.status">{{ order.status }}</span>
      <span class="mr-5"
        ><a class="button is-primary" :href="`tel:${[order.phone]}`"
          >Appeler le client</a
        ></span
      >
    </div>

    <hr />
    
    <div v-if="order.status === 'Pending'  ">
        <p
      
      @click="getOrderApproval(4)"
      data-target="modal-ter"
      class=" button is-primary is-large"
      :class="{ 'is-loading': isLoading }"
    >
      Approved ?
    </p>

    <p

      @click="getOrderApproval(3)"
      data-target="modal-ter"
      class="  button is-danger  mr-5"
      :class="{ 'is-loading': isLoading }"
    >
      Cancelled
    </p>
    
    </div>

    <div v-if="order.status === 'Cancelled'  ">
        <p
      
      @click="getOrderApproval(4)"
      data-target="modal-ter"
      class=" button is-primary is-large"
      :class="{ 'is-loading': isLoading }"
    >
      Approved ?
    </p>

    
    </div>

    <hr />

    <div class="substitle ">
      الحالة 2 :
      <span class="tag " :class="order.status_comf">{{
        order.status_comf
      }}</span>
      <span class="mr-5"
        ><a
          class="button is-success"
          :href="`https://api.whatsapp.com/send?phone=${[order.phone]}`"
          >whatsapp du client</a
        ></span
      >
    </div>

    <hr />
    <div class="field">
      <div class="control">
        <input v-model="note" class="input is-large" placeholder="Note...." />
      </div>
    </div>
    <hr />

    <!-- <p v-if="order.status === 'Approved'" @click='getOrderApproval' data-target="modal-ter" class=" button is-primary" :class="{'is-loading' : isLoading}">Approver </p> -->
    <p
      @click="getOrderApproval(1)"
      data-target="modal-ter"
      class=" button is-warning m-1"
      :class="{ 'is-loading': isLoading }"
    >
      Boite Vocale
    </p>
    <!-- <p    @click="getOrderApproval(88)" data-target="modal-ter" class=" button is-success m-1" :class="{'is-loading' : isLoading}">Delivred </p> -->
    <!-- <p    @click="getOrderApproval(16)" data-target="modal-ter" class=" button is-warning m-1" :class="{'is-loading' : isLoading}">Returned </p> -->

    <!-- <p    @click="getOrderApproval(2)" data-target="modal-ter" class=" button is-danger m-1" :class="{'is-loading' : isLoading}">Refused </p> -->

    <p
      @click="getOrderApproval(5)"
      data-target="modal-ter"
      class=" button is-warning m-1"
      :class="{ 'is-loading': isLoading }"
    >
      Injoingable
    </p>

    <p
      @click="getOrderApproval(13)"
      data-target="modal-ter"
      class=" button is-warning m-1"
      :class="{ 'is-loading': isLoading }"
    >
      Ne repond pas
    </p>
    <p
      @click="getOrderApproval(14)"
      data-target="modal-ter"
      class=" button is-dark m-1"
      :class="{ 'is-loading': isLoading }"
    >
      Faux numéro
    </p>

    <!-- <p    @click="getOrderApproval(0)" data-target="modal-ter" class=" button is-link m-1" :class="{'is-loading' : isLoading}">Transit </p>
      
        <p    @click="getOrderApproval(55)" data-target="modal-ter" class=" button is-link m-1" :class="{'is-loading' : isLoading}">Expedier vers </p> -->

    <p
      @click="getOrderApproval(66)"
      data-target="modal-ter"
      class=" button is-danger m-1"
      :class="{ 'is-loading': isLoading }"
    >
      Hors Zone
    </p>
    <p
      @click="getOrderApproval(77)"
      data-target="modal-ter"
      class=" button is-link m-1"
      :class="{ 'is-loading': isLoading }"
    >
      Reporter
    </p>

    <hr />

    <!-- <div class="control">
  <textarea class="textarea has-fixed-size" placeholder="Fixed size textarea"></textarea>
</div> -->

    <div class="columns p-5">
      <div class="column">
        اسم الزبون: <b>{{ order.name }}</b
        ><br />
        {{ term }}

        الهاتف : <b>{{ order.phone }}</b
        ><br />
        <!-- المدينة : <b>{{ order.city }}</b
        ><br /> -->

        العنوان : <b>{{ order.address }}</b>
      </div>

      <div class="column">
        <b> ثمن البيع : {{ order.amount }} درهم </b><br />

        <b>سعر المنتجات : {{ order.prix_de_vente }} درهم </b><br />

        <b>التغليف و التوضيب : {{ order.embalage_cost }} درهم </b><br />
        <b>سعر الشحن : {{ order.livraison_cost }} درهم </b><br />
        <!-- <b>تأكيد و تتبع الطلبية :  درهم </b><br /> -->
      </div>
      <div class="column">
        <!-- <div v-if="order.status === 'Returned'">
                  <span> تكاليف الإرجاع :
          <b class="tag  is-warning is-medium">
            {{ order.return_cost }} درهم </b
          ></span>
        </div>
        <div v-else-if="order.status === 'Refused'">
          <span> تكاليف الرفض   :
          <b class="tag  is-danger is-medium">
            {{ order.refus_cost }} درهم </b
          ></span>
        </div> -->
        <!-- <div v-else-if="order.status === 'Delivred'">
          <span>صافي الربح:
          <b class="tag is-success is-light is-medium">
            {{ (order.gain).toFixed(2) }} درهم </b
          ></span>
        </div> -->
        <!-- <div v-else>
          <span> الربح المتوقع :
          <b class="tag is-light is-light is-medium">
            {{ (order.gain).toFixed(2) }} درهم </b
          ></span>
        </div> -->

        <!-- <span
          v-if="order.status === 'Pending'"
          @click="deleteOrder(order.id)"
          class="button is-danger is-medium is-rounded"
          ><i class="fas fa-trash-alt"> </i
        ><b class="mr-3">حذف الطلبية</b> </span> -->
      </div>
    </div>

    <table class="card">
      <tr v-for="item in order.items" :key="item.product.id">
        <td>
          <figure class="image is-128x128">
            <img style="max-height: 120px;" :src="item.product.get_thumbnail" />
          </figure>
        </td>

        <td>{{ item.product.name }}</td>
        <td>{{ item.product.price }} درهم</td>
        <td>{{ item.quantity }}</td>
      </tr>
    </table>

    <hr />

    <div>
      <p style="background-color:#f9f9f9;" class="panel-heading ">
        تتبع الطلبية
      </p>

      <div v-if="notes.length">
        <div
          v-for="note in notes"
          :key="note.id"
          class="columns panel-block m-0"
        >
          <div class="column has-text-centered">
            <div>
              <span class="tag" :class="note.status">{{ note.status }}</span>
            </div>
          </div>
          <div class="column has-text-centered phone-hide">
            <div>
              <p v-if="note.note" class="tag ">{{ note.note }}</p>
              <p v-else class=" "></p>
            </div>
          </div>

          <div class="column has-text-centered phone-hide">
            <div>
              <p class=" ">{{ formatDate(String(note.created)) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>
      <p style="background-color:#f9f9f9;" class="panel-heading ">
        Comments 
      </p>


       <article v-for="comment in comments" class="media">
      <figure class="media-left">
        <p class="image is-64x64">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
          />
        </p>
      </figure>
      <div class="media-content">
        <div class="content">
          <p>
            <strong>{{ comment.sent_by.username }}</strong>
            <br />
            {{ comment.memo }}
            <br />
            <small> {{ formatDate(comment.created) }}</small> <small v-if='comment.is_completed' class='tag is-success'> answer</small> <small v-if='!comment.is_completed' class='tag is-danger'> Client question</small>
          </p>
        </div>
      </div>
    </article>

    <article class="media">
      <figure class="media-left">
        <p class="image is-64x64">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
          />
        </p>
      </figure>
      <div class="media-content">
        <div class="field">
          <p class="control">
            <textarea
            v-model='memo'
              class="textarea"
              placeholder="إرسال تعليق أو ملاحظة أو تساءل بخصوص هذه الطلبية "
            ></textarea>
          </p>
        </div>
        <div class="field">
          <p class="control">
            <button @click='submitToBackend' class="button">إرسال  </button>
          </p>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import moment from "moment";
import { toast } from "bulma-toast";
import axios from "axios";
export default {
  name: "Success",
  data() {
    return {
      isLoading: false,
      term: "",
      note: "",
      myterm: "",
      notes: [],
      memo:'',
      order: {},
      loading: true,
    };
  },


  methods: {


        async submitToBackend() {
      this.isLoading = true;
      

      const data = {
        
        comment: this.memo,

      };

      const order_id = this.$route.params.id;

      await axios

        .post(`/api/v1/AdmingetComment/${order_id}/`, data)
        .then((response) => {
          // this.$store.commit("clearCart");
          
          this.memo = '';
          // this.$router.push(`/UserDash/OrdersView`);
          this.getComments();

                                  toast({
          message: `تم الإرسال بنجاح  `,
          type : 'is-success',
          dismissible: true,
          duration: 4000,
          position:'bottom-right',
        })
        })
        .catch((error) => {
                          toast({
          message: `${error} `,
          type : 'is-danger',
          dismissible: true,
          duration: 4000,
          position:'bottom-right',
        })
          
          console.log(error);
        });

      this.isLoading = false;
    },


    getComments() {
      const track = this.$route.params.id;
      axios
        .get(`/api/v1/getComments/${track}/`)
        .then((response) => {
          this.comments = response.data;
          
        

          this.loading = false;
        })
        .catch((error) => {
          console.log("error", error);

          toast({
            message: `${error.response.data}`,
            type: "is-danger",
            dismissible: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
    },
    
    getNotes() {
      const track = this.$route.params.id;
      axios
        .get(`/api/v1/getnotes/${track}/`)
        .then((response) => {
          this.notes = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log("error", error);

          toast({
            message: `${error.response.data}`,
            type: "is-danger",
            dismissible: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
    },
    formatDate(value) {
      if (value) {
        return moment(value).format("DD/MM/YYYY  hh:mm");
      }
    },
    getOrder() {
      const track = this.$route.params.id;
      axios
        .get(`api/v1/getAdminOrder/${track}`)
        .then((response) => {
          this.order = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getOrderApproval(myterm) {
      const orderID = this.$route.params.id;
      this.isLoading = true;
      const data = {
        note: this.note,
      };
      axios
        .post(`/api/v1/getOrderApprove/${orderID}/${myterm}/`, data)
        .then((response) => {
          console.log(response.data);
          this.getOrder();
          toast({
            message: `${response.data}`,
            type: "is-success",
            dismissible: true,
            duration: 3000,
            position: "bottom-right",
          });
          this.isLoading = false;
        })
        .catch((error) => {
          toast({
            message: `${error}`,
            type: "is-danger",
            dismissible: true,
            duration: 3000,
            position: "bottom-right",
          });
          this.isLoading = false;
        });
    },

    deleteOrder(itemId) {
      console.log(itemId);
      console.log("itemId", itemId);
      axios
        .delete(`api/v1/deleteOrder/${itemId}/`)
        .then((response) => {
          console.log("deleted");
          toast({
            message: "The order was deleted successfully",
            type: "is-success",
            dismissible: true,
            duration: 2000,
            position: "bottom-right",
          });

          this.$router.push("/UserDash/Overview");
        })
        .catch((error) => {
          console.log(error);
          toast({
            message: `${error}`,
            type: "is-danger",
            dismissible: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
    },
  },

    mounted() {
      this.getComments();
    this.getOrder();
    this.getNotes();
    
  },
};
</script>
