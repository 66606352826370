<template>
  <div>

    <article class="panel is-white">
      <div class="panel-heading pb-1">الفواتير :     
      </div>


      <div class="panel-block">
        <p class="control has-icons-left">
          <input
            class="input is-info"
            v-model="search"
            type="text"
            placeholder="Search"
          />
          <span class="icon is-left">
            <i class="fas fa-search" aria-hidden="true"></i>
          </span>
        </p>
      </div>

      <div v-if="factures.length">
         
        <router-link
          v-for="facture in searchFactures"
          :key="facture.id"
          class="columns panel-block m-0"
          :to="{name: 'viewFacture', params: {id: facture.id}}"
        >          <div class="column has-text-centered">
            <div>
              <p class="substitle">#{{ facture.id }}</p>
            </div>
          </div>
          
          <div class="column has-text-centered">
            <div>
              <p class="substitle">{{ facture.bank_info }}</p>
            </div>
          </div>
          <div class="column has-text-centered">
            <div>
              <p class="substitle">{{ facture.amount }}MAD</p>
            </div>
          </div>
          <div class="column has-text-centered">
            <div>
              <p class="substitle">{{ facture.transaction_id }}</p>
            </div>
          </div>
          <div class="column has-text-centered">
            <div>
              <span v-if="facture.is_paid"  class="tag is-success">تم الدفع</span>
              <span v-else  class="tag is-danger">لم يتم الدفع بعد </span>

            </div>
          </div>
                  </router-link>


      </div>
      <p class="p-5" v-else>لا توجد فواتير </p>
    </article>


        <div v-if="isModel"  class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">مركز الدفع </p>

                
                <button @click="isModel = false"  class="delete" aria-label="close"></button>
                </header>
                <section class="modal-card-body">رصيدك : 
                                  <h1 class="card tag  p-3">{{user.balance_net}} درهم  </h1>

                <!-- Content ... -->
                                    <div class="field has-addons has-addons-centered">

                    <p class="control">
                        <input v-model="amount" min="1" :max="user.balance_net" class="input" type="number" placeholder="المبلغ ">
                    </p>

                    </div>
                    
                    <div class="field">
                    <label class="label">معلومات إضافية </label>
                    <div class="control">
                        <input v-model="comment" class="input" type="text" placeholder="e.g CIH , CASHPLUS, WAFACACH">
                    </div>
                    </div>

  

                </section>
                <footer class="modal-card-foot">
                <button @click="requestPay" class="button is-success">إرسال</button>
                <button @click="isModel = false"  class="button">إغلاق </button>
                </footer>
            </div>
            </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";

var filters = {
  all: function (factures) {
    return factures;
  },
  Paid: function (factures) {
    return factures.filter(function (facture) {
      return facture.is_paid === "true";
    });
  },
  notpaid: function (factures) {
    return factures.filter(function (facture) {
      return facture.is_paid === "false";
    });
  },

};
export default {
  name: "Invoives",
  data() {
    return {
      amount :'',
      comment : '',
      isModel: false,
      user: [],

      visibility: "all",
      search: "",
      factures: [],
    };
  },
  mounted() {
    document.title = "Factures | Bi3 Dashboard";

    this.getFactures();
     this.getInfos();
  },
  methods: {
        getInfos() {
      axios
        .get(`api/v1/user-info/`)
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          toast({
            message: `${error}`,
            type: "is-danger",
            dismissible: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
    },
    getFactures() {
      axios
        .get("/api/v1/factures-list/")
        .then((response) => {
          this.factures = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          toast({
            message: `${error}`,
            type: "is-danger",
            dismissible: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
    },

    requestPay(){
      const data = {
        amount : this.amount,
        bank_info : this.comment,


      }
      axios.post('api/v1/CreateFacture/' , data)
      .then(response => {
        console.log('response',response);
        const toPath = this.$route.query.to || "/UserDash/Invoices";

        this.$router.push(toPath);
        this.isModel = false
      })
      .catch(error =>{
        console.log('error:' , error);
            toast({
            message: `${error}`,
            type: "is-danger",
            dismissible: true,
            duration: 3000,
            position: "bottom-right",
          });
      })
    }
  },

  computed: {
    filteredFactures() {
      return filters[this.visibility](this.factures);
    },
    searchFactures: function () {
      return this.filteredFactures.filter((facture) => {
        return facture.amount.match(this.search);
      });
    },
  },
};
</script>

<style>
.not{
  cursor: not-allowed !important;
    pointer-events: none!important;

}
</style>