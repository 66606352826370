<template>
  <tr>
    <td>
      <router-link :to="item.product.get_absolute_url">
        <figure class="image is-96x96">
          <img class="" :src="item.product.get_thumbnail" />
        </figure></router-link
      >
    </td>
    <td>
      <router-link :to="item.product.get_absolute_url">


        {{ item.product.name }}</router-link
      >
    </td>
    <td>{{ item.product.price }}</td>
    <td>
        <button @click="downQty(item)" class=" is-dark "><i class="fas fa-minus"></i></button>
        <span style="padding:5px;" class=" is-static is-dark">{{ item.quantity }}</span>
        <button @click="upQty(item)" class=" is-dark"><i class="fas fa-plus"></i></button>
        
        </td>
    <td>{{ getItemTotal(item) }} درهم </td>
    <td><button @click="removeFromCart(item)" class="delete"></button></td>
  </tr>
</template>

<script>
export default {
  name: "CartItem",
  props: {
    initialItem: Object,
  },
  data() {
    return {
      item: this.initialItem,
    };
  },
  methods: {
    getItemTotal(item) {
      return item.quantity * item.product.price;
    },
    upQty(item){
        item.quantity += 1
        this.updateCart()

    },
    downQty(item){
        item.quantity -= 1
        if(item.quantity === 0){
            this.$emit('removeFromCart', item)

        }
        this.updateCart()

    },
    updateCart(){
        localStorage.setItem('cart', JSON.stringify(this.$store.state.cart))

    },
    removeFromCart(item){
        
        this.$emit('removeFromCart', item )
        this.updateCart()
    }
  },
};
</script>