<template>
  <div>
    <article class="panel is-white">
      <p class="panel-heading pb-1">الطلبيات</p>
      <p class="panel-tabs">
        <a
          :class="{ 'is-active': visibility === 'all' }"
          @click="visibility = 'all'"
          >All</a
        >



               <a class='tag is-danger'
          :class="{ 'is-active': visibility === 'need' }"
          @click="visibility = 'need'"
          >need answer</a
        >


        <a
          :class="{ 'is-active': visibility === 'Pending' }"
          @click="visibility = 'Pending'"
          >Pending</a
        >

        <a
          :class="{ 'is-active': visibility === 'Boite' }"
          @click="visibility = 'Boite'"
          >Boite Vocale</a
        >

        <a
          :class="{ 'is-active': visibility === 'Injoingable' }"
          @click="visibility = 'Injoingable'"
          >Injoingable</a
        >

        <a
          :class="{ 'is-active': visibility === 'Transit' }"
          @click="visibility = 'Transit'"
          >Transit</a
        >

        <a
          :class="{ 'is-active': visibility === 'Approved' }"
          @click="visibility = 'Approved'"
          >Approved</a
        >

        <a
          :class="{ 'is-active': visibility === 'Reporté' }"
          @click="visibility = 'Reporté'"
          >Reporté</a
        >

        <a
          :class="{ 'is-active': visibility === 'eme' }"
          @click="visibility = 'eme'"
          >Ne repond pas</a
        >
      </p>
      <div class="panel-block">
        <p class="control has-icons-left">
          <input
            class="input is-info"
            v-model="search"
            type="text"
            placeholder="Search"
          />
          <span class="icon is-left">
            <i class="fas fa-search" aria-hidden="true"></i>
          </span>
        </p>
      </div>

      <template v-if="!loading">
        <div v-if="orders.length">
          <router-link
            v-for="order in searchOrders"
            :key="order.id"
            class="columns panel-block m-0"
            :to="{ name: 'viewOrder', params: { id: order.track } }"
          >
            <div class="column has-text-centered phone-hide p-0">
              <div>
                <table class=" has-text-centered phone-hide">
                  <div
                    class="has-text-centered"
                    v-for="item in order.items"
                    :key="item.product.id"
                  >
                    <td class="has-text-centered card">
                      <figure
                        class=" image is-64x64 has-text-centered phone-hide p-0 m-0"
                      >
                        <img :src="item.product.get_thumbnail" />
                      </figure>
                    </td>
                  </div>
                </table>
              </div>
            </div>
            <div class="column has-text-centered">
              <div>
                <p class="substitle">{{ order.name }}</p>
              </div>
            </div>
            <div class="column has-text-centered phone-hide">
              <div>
                <p class="tag ">{{ formatDate(String(order.created_at)) }}</p>
              </div>
            </div>

            <div class="column has-text-centered phone-hide">
              <div>
                <p class="substitle">#{{ order.track }}</p>
              </div>
                        <div
              class="column has-text-centered phone-hide"
              v-if="order.has_message > 0"
            >
              <div>
                <p class="substitle">
                  <i class="far fa-comments"></i> {{ order.has_message }}
                </p>
              </div>

              <div v-if="order.has_answer <= 0 ">
                <p class="substitle is-danger  tag">need answer</p>
              </div>
            </div>
            <div v-else class="column has-text-centered phone-hide"></div>
            </div>

  
            <div class="column has-text-centered phone-hide">
              <div>
                <p class="tag ">{{ order.amount }} درهم</p>
              </div>
            </div>
            <div class="column has-text-centered phone-hide">
              <div>
                <p class=" ">{{ order.phone }}</p>
              </div>
              
            </div>
            <div class="column has-text-centered">
              <div>
                <p class="substitle">{{ order.ville.ville }}</p>
              </div>
            </div>
            <!-- <div v-if="order.status === 'Returned'">
                  <span>
          <b class="tag  is-warning is-bordered is-bold phone-hide">
            {{ order.return_cost }} درهم </b
          ></span>
        </div>
        <div v-else-if="order.status === 'Refused'">
          <span>
          <b class="tag  is-danger is-bordered is-bold phone-hide">
            {{ order.refus_cost }} درهم </b
          ></span>
        </div>
        <div v-else-if="order.status === 'Delivred'">
          <span>
          <b class="tag  is-success is-bordered is-bold phone-hide">
            {{ (order.gain).toFixed(2) }} درهم </b
          ></span>
        </div>
        <div v-else>
          <span>
          <b class="tag is-light  is-bordered is-bold phone-hide">
            {{ (order.gain).toFixed(2) }} درهم </b
          ></span>
        </div> -->

            <div class="column has-text-centered">
              <div>
                <span class="tag" :class="order.status">{{
                  order.status
                }}</span>
              </div>
              <div v-if="order.status_comf !== 'en cours'">
                <span class="tag" :class="order.status_comf">{{
                  order.status_comf
                }}</span>
              </div>
            </div>
          </router-link>
        </div>
        <p class="p-5" v-else>لا توجد طلبيات</p>
      </template>

      <template v-else class="is-vcentered p-5">
        <div class="column has-text-centered">
          <div class="m-4">
            <progress class="progress is-small is-light" max="30">15%</progress>
          </div>
        </div>
      </template>
    </article>

    <router-view></router-view>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { toast } from "bulma-toast";

var filters = {
  all: function(orders) {
    return orders;
  },
  Pending: function(orders) {
    return orders.filter(function(order) {
      return order.status === "Pending";
    });
  },

  Approved: function(orders) {
    return orders.filter(function(order) {
      return order.status === "Approved";
    });
  },

  Injoingable: function(orders) {
    return orders.filter(function(order) {
      return order.status_comf === "Injoingable";
    });
  },

  Boite: function(orders) {
    return orders.filter(function(order) {
      return order.status_comf === "Boite vocale";
    });
  },

  need: function(orders) {
    return orders.filter(function(order) {
      return order.has_message > 0;
    });
  },

  eme: function(orders) {
    return orders.filter(function(order) {
      return order.status_comf === "Ne repond pas";
    });
  },

  Transit: function(orders) {
    return orders.filter(function(order) {
      return order.status === "Transit";
    });
  },

  Reporté: function(orders) {
    return orders.filter(function(order) {
      return order.status_comf === "Reporté";
    });
  },

  Delivred: function(orders) {
    return orders.filter(function(order) {
      return order.status_comf === "Delivred";
    });
  },
};
export default {
  name: "Overview",
  data() {
    return {
      loading: true,
      visibility: "all",
      search: "",
      orders: [],
    };
  },
  mounted() {
    document.title = "Liv Orders | Bi3 Dashboard";

    this.getOrders();
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(value).format("DD/MM/YYYY   hh:mm");
      }
    },
    getOrders() {
      axios
        .get("/api/v1/admin/orders/")
        .then((response) => {
          this.orders = response.data;
          this.loading = false;
        })
        .catch((error) => {
          toast({
            message: `${error}`,
            type: "is-danger",
            dismissible: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
    },
  },

  computed: {
    filteredOrders() {
      return filters[this.visibility](this.orders);
    },
    searchOrders: function() {
      return this.filteredOrders.filter((order) => {
        return order.track.match(this.search);
      });
    },
  },
};
</script>

<style>
.Delivred {
  background-color: #29b346 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.Pending {
  background-color: #485fc7 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.Returned {
  background-color: #ffe08a !important;
  border-color: transparent !important;
  color: #fff !important;
}

.Refused {
  background-color: #f14668 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.Cancelled {
  background-color: #0e0d0d !important;
  border-color: transparent !important;
  color: #fff !important;
}

.Transit {
  background-color: #1f90db !important;
  border-color: transparent !important;
  color: #fff !important;
}

.Injoingable {
  background-color: #f09622 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.Injoingable {
  background-color: #f09622 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.Approved {
  background-color: #1fcfc0 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.tentative {
  background-color: #ffc115 !important;
  border-color: transparent !important;
  color: rgb(19, 17, 17) !important;
}

.repond {
  background-color: #ff4c15 !important;
  border-color: transparent !important;
  color: rgb(19, 17, 17) !important;
}

.Appel {
  background-color: #e8ff15 !important;
  border-color: transparent !important;
  color: rgb(19, 17, 17) !important;
}

.Faux {
  background-color: #f32828 !important;
  border-color: transparent !important;
  color: rgb(247, 247, 247) !important;
}

.injoignable {
  background-color: #ffd415 !important;
  border-color: transparent !important;
  color: rgb(19, 17, 17) !important;
}

.reporté {
  background-color: #ffd415 !important;
  border-color: transparent !important;
  color: rgb(19, 17, 17) !important;
}

.vocale {
  background-color: #fbff15 !important;
  border-color: transparent !important;
  color: rgb(19, 17, 17) !important;
}
@media only screen and (max-width: 600px) {
  .phone-hide {
    display: none !important;
  }
}
</style>
