<template>
  <div>
    <div class="column is-4">
      <div class="title">تسجيل الدخول</div>
      <hr />
      <form @submit.prevent="submitLogin" action="">
        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input
              v-model="username"
              class="input is-large"
              type="text"
              placeholder="اسم المستخدم"
              required
            />
            <span class="icon is-small is-right">
              <i class="fas fa-at"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-right">
            <input
              v-model="password"
              class="input is-large"
              type="password"
              placeholder="كلمة المرور"
              required
            />
            <span class="icon is-small is-right">
              <i class="fas fa-lock"></i>
            </span>
          </p>
        </div>

        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" :key="error">{{ error }}</p>
          <br />
        </div>
        <div>
          <div class="">
            <button
              type="submit"
              :class="{ 'is-loading': isLoading }"
            class="bd-tw-button button is-dark is-medium"
            >
              <span class="icon ml-2"><i class="fas fa-sign-in-alt"></i> </span> 
              <span>تسجيل الدخول </span>
            </button>

            <!-- <hr />
            <router-link to="/SignUp" class="button is-light is-medium">
              إنشاء حساب 
            </router-link> -->
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "SignUp",
  data() {
    return {
      username: "",
      password: "",

      isLoading: false,
      errors: [],
    };
  },
  mounted() {
    document.title = "Login | Bi3.ma";
  },

  methods: {
    submitLogin() {
      this.isLoading = true;

      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      const formData = {
        username: this.username,
        password: this.password,
      };
      axios
        .post("/api/v1/token/login/", formData)
        .then((response) => {

          const token = response.data.auth_token;
          this.$store.commit("setToken", token);
          axios.defaults.headers.common["Authorization"] = "Token " + token;
          localStorage.setItem("token", token);

          // console.log('response.data',response.data);


          const toPath = this.$route.query.to || "/AdminDash/Overview";

          this.$router.push(toPath);

  

        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              // this.errors.push(`${error.response.data[property]}`)
              toast({
                message: `${error.response.data[property]}`,
                type: "is-danger",
                dismissible: true,
                duration: 6000,
                position: "bottom-right",
              });
            }
          } else {
            this.errors.push("Something went wrong, try again!");
          }
        }),
        (this.isLoading = false);
    },
  },
};
</script>

<style scoped>
</style>