<template>
  <div>
    <table class="table is-fullwidth mb-5">
      <thead>
        <th>
          <b>Order Track: #{{ order.track }} </b>
        </th>
        <th></th>
        <th>Price</th>

        <th>Quantity</th>
        <th>items price</th>
        <th>
          <span v-if="order.status === 'Pending'" class="tag is-warning is-medium is-rounded">{{order.status}}</span>
          <span v-else-if="order.status === 'Delivred'" class="tag is-primary is-medium is-rounded">{{order.status}}</span>
        
        
        </th>

      </thead>
      <tbody>
        <tr v-for="item in order.items" :key="item.product.id">
          <td>
            <figure class="image is-24x24">
              <img :src="item.product.get_thumbnail" />
            </figure>
          </td>

          <td>{{ item.product.name }}</td>
          <td>{{ item.product.price }} MAD</td>
          <td>{{ item.quantity }}</td>
          <td>{{ getItemTotal(item) }} MAD</td>
          <td></td>
        </tr>

        <div class="columns m-3 is-fullwidth">
          <div class="column is-6">
            Client: <b>{{ order.name }}</b
            ><br />

            Telephone: <b>{{ order.phone }}</b
            ><br />
            ville: <b>{{ order.ville }}</b
            ><br />

            address: <b>{{ order.address }}</b
            ><br />
          </div>

          <div class="column is-6">
            <b>Prix Vente : {{ order.amount }} MAD</b><br />

            <b>Total Cost : {{ order.prix_de_vente }} MAD</b><br />

            <b>Prix Embalage : {{ order.embalage_cost }} MAD</b><br />
            <b>Prix Livraison : {{ order.livraison_cost }} MAD</b><br />
          </div>
          <div class="column is-6">
            <span >Gain estimated: <b class="tag is-success is-light is-medium">   {{ order.gain }} MAD</b></span>
            

            
          </div>
                    <span
            v-if="order.status === 'Pending'"
            @click="deleteOrder(order.id)"
            class="button is-danger is-medium is-rounded"
            ><i class="fas fa-trash-alt"></i></span
          >
          <!-- <div v-if="order.status ==='Delivred'">
                         <span v-if="order.is_paid  === true  " class="tag is-primary is-large">Paid</span> <span v-else class="tag is-danger is-large">Not Paid</span> 

          </div> -->

        </div>
      </tbody>
    </table>

    <hr />
  </div>
</template>

<script>
import axios from 'axios'
import {toast} from 'bulma-toast'
export default {
  name: "OrderSummary",
  props: {
    order: Object,
  },
  data() {
    return {};
  },
  methods: {
    getItemTotal(item) {
      return item.quantity * item.product.price;
    },
    deleteOrder(itemId){
          console.log(itemId);
          console.log('itemId', itemId);
          axios 
            .delete(`api/v1/deleteOrder/${itemId}/`)
            .then( response => {
              console.log('deleted');
                     toast({
                    message: 'The order was deleted successfully',
                    type : 'is-success',
                    dismissible: true,
                    duration: 2000,
                    position:'bottom-right',
                  })

                  this.$router.push('/orders')

            })
            .catch(error =>{
              console.log(error);
                    toast({
                    message: `${error}`,
                    type : 'is-danger',
                    dismissible: true,
                    duration: 2000,
                    position:'bottom-right',
                  })

            })
    }
  

    
  }
}
</script>