<template>
  <div>

    <div v-if="factures.length" class="  ">
      <table class="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th><abbr title="Position">ID</abbr></th>
            <th>BANK</th>
            <th>Transaction ID</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="facture in factures" :key="facture.id">
            <th>{{ facture.id }}</th>

            <td>{{ facture.bank_info }}</td>
            <td>{{ facture.transaction_id }}</td>
            <td>{{ facture.amount }} MAD</td>
            <td>{{ facture.created }}</td>
            <td>
              <span v-if="facture.is_paid === true" class="tag is-primary"
                >Paid</span
              >
              <span v-else class="tag is-danger">Not Paid</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-else>You have no factures</p>
  </div>
</template>


<script>
import axios from "axios";
import { toast } from "bulma-toast";

export default {
  name: "Factures",
  data() {
    return {
      userID: "",
      balance: "",
      balance_net: "",
      fees: "",
      orders_count: "",
      total_paid: "",
      factures: [],
    };
  },
  components: {},

  mounted() {
    document.title = "User Factures | Pro";

    this.getFactures();

  },
  methods: {
    getFactures() {
        const userID = this.$route.params.id
      axios
        .get(`/api/v1/factures-list/${userID}/`)
        .then((response) => {
          this.factures = response.data;
        })
        .catch((error) => {
          toast({
            message: `${error}`,
            type: "is-danger",
            dismissible: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
    },
  },
};
</script>