<template>
<div class="page-search">
    <div>Search Term: {{query}}</div>

    <hr>
    <div class="columns is-12">
            <ProductBox 
    v-for="product in products"
    :key="product.id"
    :product="product"
     />
    </div>






</div>
    
</template>

<script>
import ProductBox from '@/components/ProductBox.vue';

import axios from 'axios';
import {toast} from 'bulma-toast'
export default {
    name : 'Search',
    data() {
        return{
            products : [],
            query: ''
        }
        
    },
    components:{
        ProductBox
    },
    mounted(){
        document.title = 'Search | Pro'

        let url = window.location.search.substring(1)
        let params = new URLSearchParams(url)

        if(params.get('query')){
            this.query = params.get('query')
            this.performSearch()
        }
    },
    methods:{
        performSearch(){

            axios 
                .post('/api/v1/search/', {'query': this.query})
                .then(response =>{
                    this.products = response.data
                })
                .catch(error =>{
                    console.log(error);
                          toast({
                            message: '${error}',
                            type : 'is-danger',
                            dismissible: true,
                            duration: 2000,
                            position:'bottom-top',
                            })

                })

        }
    }
}
</script>

<style scoped>

</style>