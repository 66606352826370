<template>
  <div class="columns ">
    <div class="column is-5">
      <div class="title"> إنشاء حساب جديد مجاني </div>
      <hr />
      <form @submit.prevent="submitSignUP" action="">
        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input
              v-model="username"
              class="input is-medium"
              type="text"
              placeholder="البريد الإلكتروني / اسم المستخدم"
              required
            />
            <span class="icon is-small is-right">
              <i class="fas fa-at"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-right has-icons-right">
            <input
              v-model="telephone"
              class="input is-medium"
              type="text"
              placeholder="0612345678"
              required
            />
            <span class="icon is-small is-right">
              <i class="fas fa-phone-alt"></i>
            </span>
          </p>
        </div>
        <!-- <div class="field">
          <p class="control has-icons-right has-icons-right">
            <input
              v-model="cin"
              class="input is-medium"
              type="text"
              placeholder="رقم بطاقة التعريف "
              required
            />
            <span class="icon is-small is-right">
              <i class="fas fa-id-card"></i>
            </span>
          </p>
        </div> -->

        <div class="field">
          <p class="control has-icons-right has-icons-right">
            <input
              v-model="bank"
              class="input is-medium"
              type="text"
              placeholder="اسم البنك "
              required
            />
            <span class="icon is-small is-right">
              <i class="fas fa-id-card"></i>
            </span>
          </p>
        </div>

        <div class="field">
          <p class="control has-icons-right has-icons-right">
            <input
              v-model="store"
              class="input is-medium"
              type="text"
              placeholder="اسم المتجر "
              required
            />
            <span class="icon is-small is-right">
              <i class="fas fa-id-card"></i>
            </span>
          </p>
        </div>

        <div class="field">
          <p class="control has-icons-right has-icons-right">
            <input
              v-model="rib"
              class="input is-medium"
              type="text"
              placeholder="RIB"
              required
            />
            <span class="icon is-small is-right">
              <i class="fas fa-id-card"></i>
            </span>
          </p>
        </div>

        <div class="field">
          <p class="control has-icons-right">
            <input
              v-model="password"
              class="input is-medium"
              type="password"
              placeholder="كلمة السر"
              required
            />
            <span class="icon is-small is-right">
              <i class="fas fa-lock"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-right">
            <input
              v-model="password2"
              class="input is-medium"
              type="password"
              placeholder="اعادة إدخال كلمة السر"
              required
            />
            <span class="icon is-small is-right">
              <i class="fas fa-lock"></i>
            </span>
          </p>
        </div>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" :key="error">{{ error }}</p>
        </div>

        <div class="">
          <button
            type="submit"
            :class="{ 'is-loading': isLoading }"
            class="bd-tw-button button is-success is-large"
          >
            <span class="icon ml-3">
              <i class="fas fa-pen"></i>
            </span>
            <span> إنشاء حساب  </span>
          </button>
          <hr />

          <router-link
            to="/Login"
            class="bd-tw-button button is-dark "
          >
                      <span class="icon ml-3">
                  <i class="fas fa-sign-in-alt"></i>
            </span>
            <span> لديك حساب ؟ تسجيل الدخول  </span>
            
          </router-link>
        </div>
      </form>
    </div>
    <div class="column is-7">
      <hr>
    <div class="pricing-title has-text-centered p-5 is-danger ">
              <h3 class="title is-2 is-bold">شاهد الفيديو لتعرف أكثر </h3>
              <iframe  height="315" src="https://www.youtube.com/embed/0qxIA6em8y4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          </div>
    </div>
    </div>

  
</template>


<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "SignUp",
  data() {
    return {
      username: "",
      password: "",
      password2: "",
      telephone: "",
      store:'',
      cin: "",
      bank: "",
      rib: "",
      isLoading: false,
      errors: [],
    };
  },
  mounted() {
    document.title = "Sign up";
  },
  methods: {
    submitSignUP() {
      if (this.password !== this.password2) {
        this.errors.push("the passwords are not similars");
      }
      if (this.username === "") {
        this.errors.push("the username is missing");
      }
      if (!this.errors.length) {
        const formData = {
          username: this.username,
          password: this.password,
          telephone: this.telephone,
          store_name: this.store,
          cin: this.cin,
          rib: this.rib,
          bank: this.bank,
       
        };
        this.isLoading = true;
        axios

          .post("/api/v1/users/", formData)
          .then((response) => {
            toast({
              message: "Account created with success, Please Log In",
              type: "is-success",
              dismissible: true,
              duration: 3000,
              position: "bottom-right",
            });

            this.$router.push("/Login");
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                // this.errors.push(`${error.response.data[property]}`)
                toast({
                  message: `${error.response.data[property]}`,
                  type: "is-danger",
                  dismissible: true,
                  duration: 6000,
                  position: "bottom-right",
                });
                this.isLoading = false;
              }
            } else {
              this.errors.push("Something went wrong, try again!");
              this.isLoading = false;
            }
          });
      }
    },
  },
};
</script>

<style scoped>
</style>