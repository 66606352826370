<template>
    <div>
        <div class="title">help page</div>
        <div>
            please wait until we call you
        </div>
    </div>
</template>

<script>
export default {
    name: 'Success',
}
</script>