<template>






  <div v-if="!loading">
    <div class="page-product">
      <div class="columns is-multiline">
        <div class="column is-6">
          <figure class="image  is-5by4">
            <img class="" :src="product.get_image" alt="" />
          </figure>

        </div>
        




        <div class="column is-5">
          <h3 class="subtitle ">
            <h1 class="subtitle">{{ product.name }}</h1>
            <p>
               <strong> {{ product.price }} درهم </strong>
            </p>
          </h3>
          <hr>
          
          <button class="button is-light is-medium" @click="isCalc = !isCalc ; getCities();">
                        <i class="fa fa-calculator" aria-hidden="true"></i>
            <span class="mr-3">الحاسبة</span> 

            </button>
          <div v-if="isCalc" class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-content">
                       <div class="box">

              <nav class="level">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">تكلفة المنتج </p>
                    <p class="">{{product.price * quantity}} درهم</p>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">تكلفة الشحن </p>
                
                    <p v-if="selectedCityCost.prix_delivred" class="">{{selectedCityCost.prix_delivred}}</p>
                    <p v-else class="">--</p>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">تكلفة التأكيد و التتبع </p>
                    <p class="">13</p>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">العمولة </p>
              <td>{{ (amount * 0.1).toFixed(2) }} درهم</td>
              <!-- <td v-if="amount <= 200">{{ (amount * 0.15).toFixed(2) }} درهم</td> -->
                  </div>
                </div>
                <div class="level-item has-text-centered notification is-primary">
                  <div>
                    <p class="heading is-1">الأرباح المقدرة </p>
                    <p v-if="amount && selectedCityCost.prix_delivred" class="title">{{ ( ((amount - ((amount * (amount > 200 ? 0.1 : 0.15))).toFixed(2) - selectedCityCost.prix_delivred).toFixed(2) - 10 -3 ).toFixed(2) - (product.price) ).toFixed(2)}}</p>
                    <p v-else>0</p>
                  </div>
                </div>
              </nav>



            <div class="fields">
              <div class="field">
                <label>سعر التحصيل من العميل*</label>
               
                <div class="control">
                  <input
                    type="text"
                    placeholder="199"
                    v-bind:min="cartTotalPrice"
                    class="input"
                    v-model="amount"
                    required
                  />
                </div>
              </div>
              <article class="panel is-light" :class="{'is-primary':selectedCityCost}">
                <p class="panel-heading" >
                  المدينة : {{selectedCityCost.ville}}
                </p>

                <div class="panel-block">
                  <p class="control has-icons-left">
                    <input class="input is-info" type="text" v-model="Citysearch"  placeholder="إضغط للبحث ">
                    <span class="icon is-left">
                      <i class="fas fa-search" aria-hidden="true"></i>
                    </span>
                  </p>
                </div>
                <div class="scroll" style="max-height:200px;">
                <a  @click="Choosecity(city.id); onChange()"  v-for="city in cities" :value="city.id"  :key="city.id" class="panel-block">

                  {{city.ville}}
                </a>
                </div>
              </article>

              
              
              
              </div>

          </div>
            </div>
            <button @click="isCalc = !isCalc" class="modal-close is-large" aria-label="close"></button>
          </div>


 
          <hr>
          

          <div class="buttons">
            
            <hr />
            <input  type="number" min="1" max='10' class="input is-large mb-3" v-model="quantity" />
            <button @click="addToCart" class="button is-success is-medium"><span class="icon ml-2"><i class="fas fa-shopping-bag"></i></span><span>إضافة إلى السلة</span></button>
            <button v-if="$store.state.isAuthenticated" @click="addToFav" class="button mr-2 is-light is-medium"><span class="icon ml-2"><i class="fas fa-heart"></i></span>إضافة إلى المفضلة </button>
          </div>

          <br>


        </div>
      </div>

                      
    </div>

              <div class="columns is-multiline">
            <div class="column"> <img class="image is-2by3 pt-0" :src="product.get_image2" alt="" /></div>
            <div class="column"> <img class="image is-2by3 pt-0" :src="product.get_image3" alt="" /></div>
            <div class="column"> <img class="image is-2by3 pt-0" :src="product.get_image4" alt="" /></div>

          </div>

                    <hr>
                <div v-if="product.description" class="box">
                    <p style="white-space: pre;  " class=" ">{{ product.description }}</p>
                </div>
  </div>


  <div v-else>
       <div class="page-product">
      <div class="columns is-multiline">
        <div class="column is-6">
          <figure class="image  is-5by4">
            <progress class="progress is-small is-light" max="30">15%</progress>
          </figure>

        </div>
        




        <div class="column is-5">
          <h3 class="subtitle ">
            <h1 class="subtitle"><progress class="progress is-small is-light" max="30">15%</progress></h1>
            <p>
               <strong> <progress class="progress is-small is-light" max="30">15%</progress> </strong>
            </p>
          </h3>
                    <h3 class="subtitle ">
            <h1 class="subtitle"><progress class="progress is-small is-light" max="30">15%</progress></h1>
            <p>
               <strong> <progress class="progress is-small is-light" max="30">15%</progress>  </strong>
            </p>
          </h3>

        </div>
      </div>

                      
    </div>
    


  </div>
</template>

<script>
import axios from "axios";
import {toast} from 'bulma-toast';


export default {
  name: "Product",

  
  data() {
    return {
       loading: true,
      product: {},
      quantity: 1,
      isCalc: false,
      cities:[],
      selectedCity:'',
      selectedCityCost:'',
      amount: "",
    };
  },

  mounted() {
    this.getProduct();
    
    
  },
  methods: {
        getCities(){
        axios.get('/api/v1/cities')
        .then(response =>{
            this.cities = response.data

        })
        .catch(error =>{
            console.log('error' , error);
        })

    },
        Choosecity(city){
      this.selectedCity = city

    },
   getProduct() {
      // this.$store.commit('setIsLoading', true)
      const category_slug = this.$route.params.category_id;
      const product_slug = this.$route.params.product_id;

       axios
        .get(`/api/v1/products/${category_slug}/${product_slug}/`)
        .then((response) => {
          this.product = response.data;
          this.loading = false
          document.title = this.product.name + ' | Bi3.ma'

          
        })

        .catch((error) => {
          console.log(error);
        });
        document.title = this.product.name + ' | Bi3.ma'
      // this.$store.commit('setIsLoading', false)
      
    },
        addToFav(){
      const prid = this.product.id;
 axios
        .get(`/api/v1/addfavs/${prid}`)
        .then((response) => {
                    
        toast({
          message: `${this.product.name} was added to favorite`,
          pauseOnHover:true,
          type : 'is-dark',
          dismissible: true,
          duration: 4000,
          position:'bottom-right',
        })
        })

        .catch((error) => {
          console.log(error);
                  toast({
          message: `${error}`,
          pauseOnHover:true,
          type : 'is-danger',
          dismissible: true,
          duration: 2000,
          position:'bottom-right',
        })
        });
        document.title = this.product.name + ' | Bi3.ma'
      // this.$store.commit('setIsLoading', false)
    },
            onChange(){
      const city = this.selectedCity  ;
   

        axios

        .get(`/api/v1/city/${city}`)
        .then((response) => {
          this.selectedCityCost = response.data

        })
        .catch((error) => {
          this.errors.push(`${error}`);
          console.log(error);
        });


    },

    addToCart(){
  
        if (isNaN(this.quantity) || this.quantity < 1){
            this.quantity = 1
        }

        const item = {
            product : this.product ,
            quantity : this.quantity
        }

        this.$store.commit('addToCart', item)
        

        toast({
          message: `${item.product.name} a été ajouté au panier`,
          pauseOnHover:true,
          type : 'is-success',
          dismissible: true,
          duration: 2000,
          position:'bottom-right',
        })

    }
  },
    computed: {
 
    searchCity: function () {
      return this.cities.filter((city) => {
        return city.ville.toLowerCase().match(this.Citysearch.toLowerCase());
      });
    },






  },
};
</script>

<style scoped>
.scroll {
  
  width: 100%;
  height: 150px;
  overflow: auto;

}
</style>