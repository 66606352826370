<template >
  <div dir='rtl'>
    <div class="title">سلة المنتجات</div>
    <hr />
    <div class="columns">
      <div class="column is-7">
        <div class="box p-0">
          <table class="table is-fullwidth" v-if="cartTotalLength">
            <thead>
              <tr>
                <th>المنتج</th>
                <th>اسم المنتج</th>
                <th>سعر المنتج</th>
                <th>الكمية</th>
                <th>المجموع</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <CartItem
                v-for="item in cart.items"
                :key="item.product.id"
                :initialItem="item"
                v-on:removeFromCart="removeFromCart"
              />
            </tbody>
          </table>
          <h3 class="is-6 p-4" v-else>
            <span class="icon" data-v-483b6756="">
            <i class="fas fa-shopping-bag" data-v-483b6756="" aria-hidden="true"></i>
            </span>   سلة فارغة
          </h3>

          <div>          <router-link to="/UserDash/products" class="box is-dark"
                      ><h2 class="name mb-0">تصفح المنتجات </h2></router-link
                    ></div>
        </div>
      </div>



      <div v-if="cartTotalLength" class="column is-5 box ">
        <div class="title">الملخص :</div>
        <div>
          <b>كمية المنتجات : </b>
          <b>{{ cartTotalLength }}</b>

          <hr />
          <b>تكلفة المنتجات : </b>
          <b>{{ cartTotalPrice }} درهم </b>

          <hr />
        </div>

        <router-link v-if="cartTotalPrice > 0" to="/Checkout/COD" class="button is-success is-large m-0">
          إكمال الطلب
        </router-link>
      </div>


    </div>
  </div>
</template>


<script>
import axios from "axios";
import CartItem from "@/components/CartItem.vue";
export default {
  name: "Cart",
  components: {
    CartItem,
  },

  data() {
    return {
      cart: {
        items: [],
      },
    };
  },
  methods: {
    removeFromCart(item) {
      this.cart.items = this.cart.items.filter(
        (i) => i.product.id !== item.product.id
      );
    },
  },
  mounted() {
    this.cart = this.$store.state.cart;
  },
  computed: {
    cartTotalLength() {
      return this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.quantity);
      }, 0);
    },
    cartTotalPrice() {
      return this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.product.price * curVal.quantity);
      }, 0);
    },
  },
};
</script>


<style scoped>
</style>
