<template>
  <nav  class="navbar is-transparen ">
    <div class="navbar-brand  ">

          <router-link class="navbar-brand mr-5" to="/">
        

            <img class="" src="../../assets/logopp.png" width="80" height="50"  alt="Bi3.ma"   /> 
            
        
          </router-link>
      <div @click="showNavMobile = !showNavMobile" class="navbar-burger " data-target="navbarExampleTransparentExample">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  


    <div id="navbarExampleTransparentExample" class="navbar-menu" :class="{'is-active' : showNavMobile }">
      <div class="navbar-start ">
        <router-link v-if="!$store.state.isAuthenticated" class="navbar-item " to="/">الرئيسية</router-link>
        <router-link
          v-if="$store.state.isAuthenticated"
          class="navbar-item"
          to="/AdminDash/Overview"
        >
          لوحة التحكم
        </router-link>
        <div v-if="$store.state.isAuthenticated " class="navbar-item has-dropdown is-hoverable">
          <a v-if="$store.state.isActive" class="navbar-link" href="/"> التصنيفات </a>
          <div class="navbar-dropdown is-boxed">
            <router-link
              v-for="category in categories"
              :key="category.name"
              class="navbar-item"
              :to="category.get_absolute_url"
              >{{ category.name }}</router-link
            >
          </div>
        </div>
      </div>
      <div v-if="$store.state.isActive" class="navbar-middle level-item mr-3 ml-5">
        <form method="get" action="/search" class="field has-addons">
          <p class="control">
            <input
              class="input"
              name="query"
              type="text"
              placeholder="إبحث عن منتج"
            />
          </p>
          <p class="control">
            <button class="button">
              <i class="fas fa-search"></i>
            </button>
          </p>
        </form>
      </div>
    

      <div class="navbar-end ml-0">
        <div class="navbar-item">
          <div class="field is-grouped ">
            <p   v-if="$store.state.isAuthenticated" class="control">
              <router-link
              v-if="$store.state.isActive"
                class="bd-tw-button button ml-2"
                data-social-target="https://bi3.ma"
                to="/cart"
              >
                <span  class="icon is-left ml-2">
                  <i class="fas fa-shopping-bag"></i>
                </span>
                <span> السلة ({{ cartTotalLength }}) </span>
              </router-link>
            </p>

            <template v-if="!$store.state.isAuthenticated">
              <p class="control">
                <router-link
                  class="bd-tw-button button ml-2"
                  data-social-target="https://bi3.ma"
                  to="/login"
                >
                  <span class="icon ml-3">
                    <i class="fas fa-sign-in-alt"></i>
                  </span>
                  <span>تسجيل الدخول</span>
                </router-link>
              </p>
              <p class="control">
                <router-link class="button has-text-white" style="background-color:#213745;" to="/Signup">
                  <span class="icon ml-3">
                    <i class="far fa-plus-square"></i>
                  </span>
                  <span>إنشاء حساب جديد</span>
                </router-link>
              </p>
            </template>
            <template v-else>
              <p class="control">
                <button
                  @click="logout"
                  class="button is-light"
                  :class="{ 'is-loading': isLoading }"
                >
                  <i class="fas fa-sign-in-alt"></i>

                  <b class="mr-2">تسجيل الخروج</b>
                </button>
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>


<script>
import axios from "axios";

export default {
  name: "Navbar",

  data() {
    return {
      isLoading:false,
      showNavMobile : false,
      isActivated: false,
      cart: {
        items: [],
        
      },
      categories: [],
    };
  },

  mounted() {

    this.cart = this.$store.state.cart;

    document.title = "Admin Bi3 - Official website";
  },
  computed: {
    cartTotalLength() {
      let totalLength = 0;
      for (let i = 0; i < this.cart.items.length; i++) {
        totalLength += this.cart.items[i].quantity;
      }
      return totalLength;
    },
  },

  methods: {



    logout() {
      this.isLoading = true;
      axios.defaults.headers.common["Autorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userid");

      this.$store.commit("removeToken");
      this.$router.push("/Login");
    },

  },
};
</script>


<style >

  body{
        font-family: 'Cairo', sans-serif !important;
  }

  @media only screen and (max-width: 600px) {

  .logo-space{
    justify-content: space-around;
  }
}
 
</style>
