<template>
    <div>
        <div class="title">
            Orders
        </div>

        <div v-if="orders.length" class="is-fullwidth box " >
            
            <OrderSummary 
            class="mb-4"
            v-for="order in orders" 
            :key="order.id"
            v-bind:order="order"
             />

        </div>
        <p v-else > You have no orders</p>


    </div>
</template>


<script>
import axios from 'axios'
import {toast} from 'bulma-toast'
import OrderSummary from '@/views/OrderSummary.vue'

export default {
    name: 'Orders',
    data(){
        return{
            orders : []
        }
    },
    components:{
        OrderSummary
    },

    mounted(){
        document.title = 'Orders | Pro'

        this.getOrders()
    },
    methods:{
        getOrders(){
            axios 
               .get('/api/v1/orders/')
               .then( response =>{
                   this.orders = response.data
                   console.log(response.data);
               })
               .catch(error =>{
                   toast({
                        message: `${error}`,
                        type : 'is-danger',
                        dismissible: true,
                        duration: 2000,
                        position:'bottom-right',
                        })

               })
        }
    }
}
</script>