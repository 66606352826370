<template>

        <article class="panel is-white">
      <p class="panel-heading pb-1">الطلبيات </p>

      <div class="panel-block">
        <p class="control has-icons-left">
          <input
            class="input is-info"
            v-model="search"
            type="text"
            placeholder="Search"
          />
          <span class="icon is-left">
            <i class="fas fa-search" aria-hidden="true"></i>
          </span>
        </p>
      </div>

      <template v-if="!loading">

      

      <div  v-if="orders.length">
         
        <router-link
          v-for="order in orders"
          :key="order.id"
          class="columns panel-block m-0"
          :to="{name: 'viewOrder', params: {id: order.track}}"
        >
          
          <div class="column has-text-centered">
            <div>
              <p class="substitle">#{{ order.track }}</p>
            </div>
          </div>

          
          <div class="column has-text-centered phone-hide">
            <div>
              <p class="tag " >{{ order.amount }} درهم </p>
            </div>
          </div>
          <div class="column has-text-centered">
            <div>
              <p class="substitle">{{ order.ville.ville }}</p>
            </div>
          </div>
                <div v-if="order.status === 'Returned'">
                  <span>
          <b class="tag  is-warning is-bordered is-bold">
            {{ order.return_cost }} درهم </b
          ></span>
        </div>
        <div v-else-if="order.status === 'Refused'">
          <span>
          <b class="tag  is-danger is-bordered is-bold">
            {{ order.refus_cost }} درهم </b
          ></span>
        </div>
        <div v-else-if="order.status === 'Delivred'">
          <span>
          <b class="tag  is-success is-bordered is-bold">
            {{ (order.gain).toFixed(2) }} درهم </b
          ></span>
        </div>
        <div v-else>
          <span>
          <b class="tag is-light  is-bordered is-bold">
            {{ (order.gain).toFixed(2) }} درهم </b
          ></span>
        </div>



          <div class="column has-text-centered">
            <div>
              <span class="tag" :class='order.status'>{{ order.status }}</span>
            </div>
          </div>
                  </router-link>
                  


      </div>
      <p class="p-5" v-else>لا توجد طلبيات </p>

      </template>

      <template v-else class="is-vcentered p-5">       

<div class="column has-text-centered">
  
        <div class="m-4">
            <progress class="progress is-small is-light" max="30">15%</progress> 
        </div>
          </div>

      </template>
      
    </article>
</template>


<script>
import axios from 'axios'
import {toast} from 'bulma-toast'
import OrderSummary from '@/views/OrderSummary.vue'

export default {
    name: 'Orders',
    data(){
        return{
            search: "",
            orders : []
        }
    },
    components:{
        OrderSummary
    },

    mounted(){
        document.title = 'User Orders | Bi3.ma'

        this.getOrders()
    },
    methods:{
        getOrders(){
            const userID = this.$route.params.id
            axios 
               .get(`/api/v1/orders/${userID}/`)
               .then( response =>{
                   this.orders = response.data
                   console.log(response.data);
               })
               .catch(error =>{
                   toast({
                        message: `${error}`,
                        type : 'is-danger',
                        dismissible: true,
                        duration: 2000,
                        position:'bottom-right',
                        })

               })
        }
    },
    computed: {

    searchOrders: function () {
      return this.filteredOrders.filter((order) => {
        return order.track.match(this.search);
      });
    },
  },
}
</script>