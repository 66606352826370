<template>
  <div>
    <div class="title">المنتجات المفضلة :</div>
    <div v-if="LastProducts" class="columns is-multiline">
      <ProductBox
        v-for="product in LastProducts"
        :key="product.id"
        :product="product"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ProductBox from "@/components/ProductBox";
export default {
  name: "Fav",
  components: {
    ProductBox,
  },
    data() {
      return {
        LastProducts: [],
      };
    },
    mounted() {
      this.getLastProducts();
    },
    methods: {
      getLastProducts() {
        axios
          .get("api/v1/favs/")
          .then((response) => {
            this.LastProducts = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  
};
</script>