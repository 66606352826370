<template>
  <div>
    <div class="box">
      <div class="is-flex is-justify-content-space-between">
        <span class="title">الحساب</span>

      </div>
      <hr>

      <nav class="level is-mobile">
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">عدد الطلبات </p>
      <p class="title">{{user.orders_count}}</p>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">المبلغ المدفوع </p>
      <p class="title">{{user.total_paid}} </p>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">الرصيد </p>
      <p class="title">{{user.balance}}</p>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">الرصيد الصافي </p>
      <p class="title">{{user.balance_net}}</p>
    </div>
  </div>
</nav>
      <hr>

      <div class="  is-5">

        username:
        <input

          class="input is-medium"
          type="text"
          name=""
          :value="user.username"
          id=""
        disabled />

        store name:
        <input

          class="input is-medium"
          type="text"
          name=""
          :value="user.store_name"
          id=""
        disabled />

                CIN:
        <input
          class="input is-medium"
          type="text"
          name=""
          :value="user.cin"
          id=""
          disabled
        />

        Bank Name:
        <input
          class="input  is-medium"
          type="text"
          name=""
          :value="user.bank"
          id=""
          disabled
        />


        RIB:
        <input
          class="input is-medium"
          type="text"
          name=""
          :value="user.rib"
          id=""
          disabled
        />


        telephone:
        <input
          class="input is-medium "
          type="text"
          name=""
          :value="user.telephone"
          id=""
         disabled
        />
        <hr>


      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import {toast} from 'bulma-toast'
export default {
  name: "Account",
  data() {
    return {
      isLoading: false,
      isActive: true ,

      user: []

    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    logout() {
      this.isLoading = true;
      axios.defaults.headers.common["Autorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userid");

      this.$store.commit("removeToken");
      this.$router.push("/Login");
    },
    getInfo() {
      axios
        .get("/api/v1/user-info/")
        .then((response) => {
          this.user = response.data;
          
          

        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateUser(){

        const userid = this.id
        console.log('userid:', userid);

        const UpdateData = {


          'telephone' : this.telephone,
          'rib' : this.rib,
          // 'id': this.id,
          // 'username': this.username


          // 'rib' : this.rib,
          // 'bank' : this.bank,
        }
        axios
            .put(`/api/v1/user-update/${userid}/`, UpdateData)
            .then(response =>{
                this.cin = response.data.cin
                this.telephone = response.data.telephone
                this.rib = response.data.rib
                this.bank = response.data.bank
                      toast({
                    message: `updated with success`,
                    type : 'is-success',
                    dismissible: true,
                    duration: 2000,
                    position:'bottom-right',
                    })

            })
            .catch(error =>{
                      toast({
                    message: `${error}`,
                    type : 'is-danger',
                    dismissible: true,
                    duration: 2000,
                    position:'bottom-right',
                    })
            })
    }
  },
};
</script>


<style scoped>
</style>
