<template>
  <div>
    <div class="title">صنف : {{ category.name }}</div>
    <hr>

    <div class="columns is-multiline">
        <ProductBox
        v-for="product in category.products "
        :key='product.id'
        :product='product'

         />

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import ProductBox from '@/components/ProductBox'
export default {
    name: 'Category',

    data() {
        return {
            category: {
                products: []
            }
        }
    },
    mounted() {
        this.getCategory()
    },

    watch:{
        $route(from, to){
            if(to.name === 'Category'){
                this.getCategory()
            }
        }

    },
    components:{
        ProductBox,

    },

    methods: {
         getCategory() {
            const categorySlug = this.$route.params.category_slug
            
            axios
                .get(`/api/v1/products/${categorySlug}/`)
                .then(response => {
                    this.category = response.data
                    document.title = this.category.name + ' | Bi3.ma'
                })
                .catch(error => {
                    console.log(error)
                    // toast({
                    //     message: ` ${error} `,
                    //     type: 'is-danger',
                    //     dismissible: true,
                    //     pauseOnHover: true,
                    //     duration: 1000,
                    //     position: 'bottom-right',
                    // })
                })
          
        }
    }
}
</script>