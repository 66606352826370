<template>
  <div>
  <router-link to="/AdminDash/Adminusers"  class="button ml-5">رجوع </router-link> 
    <b class="title">الزبون  # {{user.id}}</b> <span class='mr-5'><a class='button is-dark' :href="`tel:${[user.telephone]}`">Appeler le client</a></span>

    <hr />
                <p @click='getApproval' data-target="modal-ter" class="title is-4 button is-primary" :class="{'is-loading' : isLoading}">Approver </p>


    <div class="card">
      <div class="card-content">
        <div class="media">
          <!-- <div class="media-left">
            <figure class="image is-48x48">
              <img
                src="https://bulma.io/images/placeholders/96x96.png"
                alt="Placeholder image"
              />
            </figure>
          </div> -->
          <div class="media-content">
            <p class="title is-4">{{ user.username }}</p>
            <!-- <p class="subtitle is-6">{{ user.telephone }}</p> -->
            <p class="subtitle is-1 ">الهاتف  : {{ user.telephone }}</p>
                        <p class="title is-2">البنك : {{ user.bank }}</p>
            <p class="subtitle is-3">RIB: {{ user.rib }}</p>
          </div>
          <!-- <div class="media-content">
            <p class="title is-4">البطاقة الوطنية : {{ user.cin }}</p>
            
          </div> -->
          <hr>
          


        </div>
        <hr />
        

        <nav class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">الرصيد </p>
              <p class="title">{{user.balance}} درهم </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">عدد الطلبات </p>
              <p class="title">{{user.orders_count}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">المدفوعات </p>
              <p class="title">{{user.total_paid}} درهم </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">الرصيد الصافي </p>
              <p class="title">{{user.balance_net}} درهم </p>
            </div>
          </div>
        </nav>
        
        
        <div v-if="isModel"  class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Payement Center</p>
                {{user.bank}}

                {{user.rib}}
                <button @click="isModel = false"  class="delete" aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                <!-- Content ... -->
                                    <div class="field has-addons has-addons-centered">
                    <p class="control">
                        <span class="select">
                        <select>
                            <option>$</option>

                        </select>
                        </span>
                    </p>
                    <p class="control">
                        <input v-model="amount" class="input" type="text" placeholder="Amount of money">
                    </p>
                    <p class="control">
                        <a class="button is-primary">
                        Transfer
                        </a>
                    </p>
                    </div>
                    <div class="field">
                    <label class="label">Bank info</label>
                    <div class="control">
                        <input v-model="bank_info" class="input" type="text" placeholder="e.g Alex Smith">
                    </div>
                    </div>


                    <div class="field">
                    <label class="label">transaction_id</label>
                    <div class="control">
                        <input v-model="transaction_id" class="input" type="number" placeholder="transaction_id">
                    </div>
                    </div>
                     <div class="field">

                    <label class="checkbox">
                    <input v-model="is_paid" type="checkbox">
                    marquer comme paid
                    </label>
                    </div>

                </section>
                <footer class="modal-card-foot">
                <button @click="payUser" class="button is-success">Payer</button>
                <button @click="isModel = false"  class="button">Cancel</button>
                </footer>
            </div>
            </div>

        <hr />

        <div class="content">
          <div v-if='user.method'>Method: {{user.method}}</div>

        </div>
      </div>
    </div>
  


      <div  class="section  is-light">
          <div class="tabs is-large">
  <ul>
    <li><router-link :to="{name: 'UserOrders', params: {id: $route.params.id}}">الطلبات </router-link></li>
    <li><router-link :to="{name: 'UserFactures', params: {id: $route.params.id}}">الفواتير </router-link></li>
  </ul>
</div>
      <router-view class="content is-light" />
    </div>

  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
name: "Payuser";
export default {
  data() {
    return {
    isModel: false,
    isLoading: false,
    link:'',
      user: [],
      userid: '',
      amount : '',
      bank_info:'',
      transaction_id:'',
      is_paid: '',
    };
  },

  mounted() {
    this.getInfos();
          const userID = this.$route.params.id;
      this.userid = userID

  },
  methods: {
    getInfos() {
      const userID = this.$route.params.id;

      axios
        .get(`api/v1/user-info/${userID}/`)
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          toast({
            message: `${error}`,
            type: "is-danger",
            dismissible: true,
            duration: 2000,
            position: "bottom-right",
          });
        });
    },

    getApproval(){
        const userID = this.$route.params.id
        this.isLoading = true
        axios   
            .post(`/api/v1/getApprove/${userID}/`,)
            .then(response =>{
                console.log(response.data);
                      toast({
                        message: `approved with success!`,
                        type : 'is-success',
                        dismissible: true,
                        duration: 3000,
                        position:'bottom-right',
                        })
                        this.isLoading = false
            })
            .catch(error =>{
               
                toast({
                message: `${error}`,
                type : 'is-danger',
                dismissible: true,
                duration: 3000,
                position:'bottom-right',
                })
                this.isLoading = false
            })

    },
    payUser(){

        const data = {
                amount : this.amount,
                bank_info: this.bank_info,
                transaction_id: this.transaction_id,
                is_paid: this.is_paid,

        }

        const userID = this.$route.params.id

        axios   
            .post(`/api/v1/CreateFacture/${userID}/`, data)
            .then(response =>{
                console.log(response.data);
                      toast({
                        message: 'payement facture created with success!',
                        type : 'is-success',
                        dismissible: true,
                        duration: 2000,
                        position:'bottom-right',
                        })
            })
            .catch(error =>{
                console.log(error);
            })
    },

  },
};
</script>

<style>
</style>