import { createRouter, createWebHistory } from 'vue-router'

import Product from '../views/Product.vue'
import Category from '../views/Category.vue'
import Search from '../views/Search.vue'
import Cart from '../views/Cart.vue'
import SignUp from '../views/SignUp.vue'
import Login from '../views/Login.vue'
import Account from '../views/Account.vue'
import store from '../store'
import Checkout from '../views/Checkout.vue'
import Success from '../views/Success.vue'
import Orders from '../views/Orders.vue'
import CheckoutCOD from '../views/CheckoutCOD.vue'

import Factures from '../views/Factures.vue'
import AdminOrders from '../views/AdminOrders.vue'

import Adminusers from '../views/Adminusers.vue'

import Payuser from '../views/Payuser.vue'
import UserOrders from '../views/UserOrders.vue'
import UserFactures from '../views/UserFactures.vue'

import AdminDash from '../views/AdminDash.vue'
import Overview from '../views/Overview.vue'
import viewOrder from '../views/viewOrder.vue'
import Invoices from '../views/Invoices.vue'
import viewFacture from '../views/viewFacture.vue'
import Fav from '../views/Fav.vue'
import UserProducts from '../views/UserProducts.vue'


const routes = [

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),

  },
  {
    path: '/:category_id/:product_id/',
    name: 'Product',
    component: Product,
    meta: {
      requiredLogin: true,
      requiredActive: true
    }
  },
  {
    path: '/:category_slug/',
    name: 'Category',
    component: Category,
    meta: {
      requiredLogin: true
    }
  },

  {
    path: '/UserFactures/',
    name: 'UserFactures',
    component: UserFactures,
    meta: {
      requiredLogin: true
    }
  },

  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/AdminDash',
    name: 'AdminDash',
    component: AdminDash,
    children:[
      {path: 'Overview' , name:'Overview', component: Overview},
      {path: 'viewOrder/:id' , name:'viewOrder', component: viewOrder},
      {path: 'Invoices' , name:'Invoices', component: Invoices},
      {path: 'viewFacture/:id' , name:'viewFacture', component: viewFacture},
      {path: 'Favs' , name:'Fav', component: Fav},
      {path: 'Products' , name:'UserProducts', component: UserProducts},
      {path: 'account' , name:'Account', component: Account},
      {path: 'Adminusers' , name:'Adminusers', component: Adminusers},
      {path: 'Factures' , name:'Factures', component: Factures},

      

      {path: 'AdminOrders' , name:'AdminOrders', component: AdminOrders},
      {path: 'Orders' , name:'Orders', component: Orders},
      {path: '/ViewUser/:id/' , name:'Payuser', component: Payuser ,     children:[
        { path: 'UserOrders' , name: 'UserOrders',  component : UserOrders, },
        { path: 'UserFactures' , name: 'UserFactures',  component : UserFactures, },
  
      ],},


      


    ],
    meta: {
      requiredLogin: true,
     
    }


  },

  {
    path: '/Login' ,
    name: 'Login',
    component : Login
  }

  ,

  {
    path: "/:catchAll(.*)",
    name: 'catchAll',
    component: Login
 }





]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode:'history',
  routes,

})

router.beforeEach((to , from , next) =>{
    if(to.matched.some(record => record.meta.requiredLogin) && !store.state.isAuthenticated  ){
      next({name: 'Login', query:{to: to.path}});
    } else{
      next()

    }
})


export default router
