<template>
    <div>
        <div class="title">
            الفواتير :
        </div>


            
      <nav class="level is-mobile">
            <div class="level-item has-text-centered">
    <div class="notification is-success">
      <p class="heading">BALANCE COD NET</p>
      <p class="title">{{balance_net}} MAD</p>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">BALANCE COD</p>
      <p class="title">{{balance}} MAD</p>
    </div>
  </div>
    <div class="level-item has-text-centered">
    <div>
      <p class="heading">FEES COD</p>
      <p class="title">{{fees}} MAD</p>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">ORDERS COUNT</p>
      <p class="title">{{orders_count}}</p>
    </div>
  </div>

  <div class="level-item has-text-centered">
    <div>
      <p class="heading">TOTAL PAID</p>
      <p class="title">{{total_paid}} MAD</p>
    </div>
  </div>
</nav>
<hr>

        <div class="notification is-primary is-light">
            <button class="delete"></button>
            You can ask to widthraw your money once you get the minimum amount, which is represented in 500 MAD
               <a>Contact us for more informations</a> .
            </div>


      <hr />

        <div v-if="factures.length" class="  " >
    <table class="table is-striped is-fullwidth">
  <thead>
    <tr>
      <th><abbr title="Position">ID</abbr></th>
      <th>BANK</th>
      <th>Transaction ID</th>
      <th>Amount</th>
      <th>Date</th>
      <th>Status</th>



    </tr>
  </thead>

  <tbody>
    <tr v-for="facture in factures" :key="facture.id">
      <th>{{facture.id}}</th>

      <td>{{facture.bank_info}}</td>
      <td>{{facture.transaction_id}}</td>
      <td>{{facture.amount}} MAD</td>
      <td>{{facture.created }} </td>
      <td> <span v-if="facture.is_paid  === true" class="tag is-primary">Paid</span> <span v-else class="tag is-danger">Not Paid</span>  </td>
     



    </tr>
  </tbody>
            </table>
            


        </div>
        <p v-else > You have no factures</p>


    </div>
</template>


<script>
import axios from 'axios'
import {toast} from 'bulma-toast'

export default {
    name: 'Factures',
    data(){



        return{
                    balance : '',
                    balance_net:'',
        fees : '',
        orders_count : '',
        total_paid: '',
            factures : []
        }
    },
    components:{
        
    },

    mounted(){
        document.title = 'Factures | Pro'

        this.getFactures()
        this.getStats()
    },
    methods:{
        getFactures(){
            axios 
               .get('/api/v1/factures-list/')
               .then( response =>{
                   this.factures = response.data
                   console.log(response.data);
               })
               .catch(error =>{
                   toast({
                        message: `${error}`,
                        type : 'is-danger',
                        dismissible: true,
                        duration: 2000,
                        position:'bottom-right',
                        })

               })
        },
                    getStats(){
            axios 
               .get('/api/v1/user-info/')
               .then( response =>{
                   this.balance = response.data.balance
                   this.fees = response.data.fees
                   this.orders_count = response.data.orders_count
                   this.total_paid = response.data.total_paid
                   this.balance_net = response.data.balance_net

                   


                   

                   console.log(response.data);
               })
               .catch(error =>{
                   toast({
                        message: `${error}`,
                        type : 'is-danger',
                        dismissible: true,
                        duration: 2000,
                        position:'bottom-right',
                        })

               })
        }
    },


    

}
</script>