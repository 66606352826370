<template>

  <div >
        <div class="title"> المنتجات :</div>
    <div v-if="!loading">


    <div v-if="LastProducts" class="columns is-multiline">
      <ProductBox
        v-for="product in LastProducts"
        :key="product.id"
        :product="product"
      />

    </div>

    </div>
          <div class="is-vcentered is-2" v-else>
      <progress class="progress is-small is-light" max="30">15%</progress>
       <progress class="progress is-small is-light" max="30">55%</progress>
        <progress class="progress is-small is-light" max="30">45%</progress>

  </div>

  </div>
  


</template>

<script>
import axios from 'axios';
import ProductBox from "@/components/ProductBox";
export default {
  name: "Fav",
  components: {
    ProductBox,
  },
    data() {
      return {
        loading: true,
        LastProducts: [],
      };
    },
    mounted() {
      this.getLastProducts();
    },
    methods: {
      getLastProducts() {
        axios
          .get("api/v1/latest-products/")
          .then((response) => {

            this.LastProducts = response.data;
            this.loading = false
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },

};
</script>


<style >

</style>