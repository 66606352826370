<template>


  <div class="column is-3">
    <div class="box pt-0">
      <div class="card-image  ">
        <figure class="image m-0  is-1by1">
          <img :src="product.get_thumbnail" :alt="product.name" />
        </figure>
      </div>
      <div class="pt-2">
        <div class="content">
          {{ product.name }}
        </div>
        <div class="content is-bold">
          {{ product.price }} درهم
        </div>
        <!-- <div style="font-size:12px;" class="subtitle is-6">
          لو بيعت ب 99 درهم ستربح  59 درهم 
        </div> -->

              <router-link :to="product.get_absolute_url" class="button is-warning is-fullwidth"
        ><i class="far fa-eye"></i>
        </router-link
      >
      </div>


    </div>
  </div>

</template>

<script>
export default {
    name: 'ProductBox',
    props:{
        product: Object

    },
    data(){
        return{

        }
    }
}
</script>
